import classNames from 'classnames';

import { Chip, ChipTheme } from '@shared/ui-components';

import { DropdownMenu, ScrollableContainer } from '../../../../../../components';
import { type useCarouselMagazineIssueFilter } from '../../hooks/use-carousel-magazine-issue-filter';

import styles from './magazine-issue-filter-controls.module.scss';

export const MagazineIssueFilterControls = ({
  filterState,
  triggerLabel,
}: {
  filterState: ReturnType<typeof useCarouselMagazineIssueFilter>;
  triggerLabel: string;
}) => {
  const {
    currentMagazineIssueFilter,
    magazineIssueFilterDisplayedItems,
    magazineIssueFilterDropdownItems,
    setCurrentMagazineIssueFilter,
  } = filterState;

  const magazineIssueChips = magazineIssueFilterDisplayedItems &&
    magazineIssueFilterDisplayedItems.length > 1 && (
      <ul className={styles['filter__item-list']}>
        {magazineIssueFilterDisplayedItems.map((magazineIssueFilterItem) => (
          <li key={JSON.stringify(magazineIssueFilterItem.value)}>
            <Chip
              aria-current={magazineIssueFilterItem.selected}
              aria-label={magazineIssueFilterItem.ariaLabel}
              className={styles['filter__item']}
              label={magazineIssueFilterItem.label ?? ''}
              onClick={() => setCurrentMagazineIssueFilter?.(magazineIssueFilterItem.value)}
              theme={magazineIssueFilterItem.selected ? ChipTheme.Active : ChipTheme.Light}
            />
          </li>
        ))}
      </ul>
    );

  const moreOptionsDropdown = magazineIssueFilterDropdownItems &&
    magazineIssueFilterDropdownItems.length > 1 && (
      <DropdownMenu
        items={magazineIssueFilterDropdownItems.map((magazineIssueFilterItem) => ({
          'aria-label': magazineIssueFilterItem.ariaLabel,
          children: <>{magazineIssueFilterItem.label}</>,
          id: JSON.stringify(magazineIssueFilterItem.value),
          onAction: () => setCurrentMagazineIssueFilter?.(magazineIssueFilterItem.value),
          selected: magazineIssueFilterItem.selected,
        }))}
        menuProps={{
          selectedKeys: currentMagazineIssueFilter
            ? [JSON.stringify(currentMagazineIssueFilter)]
            : [],
          selectionMode: 'single',
        }}
        selected={magazineIssueFilterDropdownItems.some((dropdownItem) => dropdownItem.selected)}
        shouldMenuFlip
        triggerLabel={triggerLabel}
      />
    );

  return (
    <>
      {(magazineIssueChips || moreOptionsDropdown) && (
        <ScrollableContainer className={classNames(styles['filter'])}>
          {magazineIssueChips}
          {moreOptionsDropdown}
        </ScrollableContainer>
      )}
    </>
  );
};
