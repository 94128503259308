'use client';

import { ChipTheme, SettingsIcon } from '@shared/ui-components';

import { Carousel, Chip } from '../../../../components';
import { type LearnContentCarouselCardFragment } from '../../../../utils/data/graphql/_generated/gql-generated';
import { AccountSettingsFormSection } from '../../../../utils/enums/account-settings-form-section';
import { BASED_ON_YOUR_INTERESTS, MOSTLY_VIEWED_CAROUSEL_TYPE } from '../../constants';
import { type LearnGenericTextsCmsContent } from '../../graphql/get-learn-generic-texts-cms-content';

import { LearnContentCarouselCard } from './components';
import { MagazineIssueFilterControls } from './components/magazine-issue-filter-controls/magazine-issue-filter-controls';
import { SourceFilterControls } from './components/source-filter-controls/source-filter-controls';
import { useCarouselMagazineIssueFilter, useCarouselSourceFilter } from './hooks';
import styles from './learn-content-carousel.module.scss';

export type LearnContentCarouselProps = {
  cards: LearnContentCarouselCardFragment[];
  carouselType: string | null;
  className?: string;
  cmsContent: LearnGenericTextsCmsContent;
  selectedSourcesIds: number[];
  showMagazineIssueFilter: boolean;
  showSourcesFilter: boolean;
  sourcesFilterAllSelectionLabel: string | null | undefined;
  title: string;
};

export const LearnContentCarousel = ({
  cards,
  carouselType,
  className,
  cmsContent,
  selectedSourcesIds,
  showMagazineIssueFilter,
  showSourcesFilter,
  sourcesFilterAllSelectionLabel,
  title,
}: LearnContentCarouselProps) => {
  const sourceFilter = useCarouselSourceFilter({
    cards,
    selectedSourcesIds,
    showSourcesFilter,
    sourcesFilterAllSelectionLabel,
  });
  const { filterCardsBySelectedSource, sourceFilterItems } = sourceFilter;

  const magazineIssueFilter = useCarouselMagazineIssueFilter({
    cards,
    showMagazineIssueFilter,
  });
  const {
    filterCardsBySelectedMagazineIssue,
    magazineIssueFilterDisplayedItems,
    magazineIssueFilterDropdownItems,
  } = magazineIssueFilter;

  const filteredCards = filterCardsBySelectedMagazineIssue(filterCardsBySelectedSource(cards));

  const showCardImageWithNumber = carouselType === MOSTLY_VIEWED_CAROUSEL_TYPE;
  const isBasedOnYourInterestsCarousel = carouselType === BASED_ON_YOUR_INTERESTS;

  const selectedFilterValue =
    magazineIssueFilterDropdownItems.find((dropdownItem) => dropdownItem.selected)?.label ||
    magazineIssueFilterDisplayedItems.find((filterItem) => filterItem.selected)?.label ||
    sourceFilterItems.find((filterItem) => filterItem.selected)?.label;

  return (
    <Carousel
      className={className}
      containerClassName={styles['carousel__container']}
      filterAreaNode={
        <>
          <SourceFilterControls filterState={sourceFilter} />
          <MagazineIssueFilterControls
            filterState={magazineIssueFilter}
            triggerLabel={cmsContent.menuTrigger}
          />
          {isBasedOnYourInterestsCarousel && (
            <Chip
              aria-label={cmsContent.accountSettingsLinkLabel}
              icon={<SettingsIcon />}
              isRounded
              label={undefined}
              theme={ChipTheme.Light}
              to={`${cmsContent.pagePaths.accountSettingPagePath}#${AccountSettingsFormSection.SitePreferences}`}
            />
          )}
        </>
      }
      itemClassName={styles['carousel__item']}
      resetSlideOnChildrenChange={showSourcesFilter || showMagazineIssueFilter}
      responsive={{
        laptop: {
          breakpoint: { max: 1299, min: 1024 },
          items: 4,
        },
        laptopWide: {
          breakpoint: { max: 1599, min: 1300 },
          items: 5,
        },
        mediumBreakpoint: {
          breakpoint: { max: 939, min: 768 },
          items: 4,
        },
        mobile: {
          breakpoint: { max: 767, min: 510 },
          items: 3,
        },
        mobileNarrow: {
          breakpoint: { max: 509, min: 0 },
          items: 2,
        },
        tablet: {
          breakpoint: { max: 1023, min: 940 },
          items: 3,
        },
      }}
      title={title}
    >
      {filteredCards.map((card, index) => (
        <div key={card?.ContentLink?.GuidValue}>
          <LearnContentCarouselCard
            card={card}
            carouselTitle={title}
            cmsContent={cmsContent}
            selectedFilterValue={selectedFilterValue}
            svgNumber={showCardImageWithNumber ? index + 1 : undefined}
          />
        </div>
      ))}
    </Carousel>
  );
};
